/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react"
import { useTranslation } from "react-i18next"

/* Components */
import SEO from "../components/seo/seo"
import Wrapper from "../components/wrapper/wrapper"
import HeroText from "../components/hero-text/hero-text"
import Container from "../components/container/container"
import Header from "../components/header/header"
import Footer from "../components/footer/footer"

export default function NotFound({ location }) {
  const { t } = useTranslation()

  const PageTitle = t("pages.404.title")
  const PageDescription = t("meta.description")

  return (
    <Wrapper>
      <SEO title={PageTitle} description={PageDescription} />
      <HeroText
        idTitle="404-hero-title"
        title={t("pages.404.hero.title")}
        subtitle={t("pages.404.hero.subtitle")}
        text={t("pages.404.hero.text")}
        textKey="pages.404.hero.text"
        classVariant="heroText404"
      >
        <Container>
          <Header classVariant="headerText" location={location} />
        </Container>
      </HeroText>
      <Footer />
    </Wrapper>
  )
}
